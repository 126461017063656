import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/tacticalImg"
import Hero from "../components/hero"
import Benefit1 from "../images/benefit1.svg"
import Benefit2 from "../images/benefit2.svg"
import Benefit3 from "../images/benefit3.svg"
import Benefit4 from "../images/benefit4.svg"
import Benefit5 from "../images/benefit5.svg"
import Benefit6 from "../images/benefit6.svg"

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  grid-template-areas:
    "item1 item3 item6 item2"
    ". item4 item5 .";
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "item1"
      "item3"
      "item6"
      "item2"
      "item4"
      "item5";
  }
`

const Benefit = styled.div`
  background: var(--soft);
  border-radius: 2px 2px 50px 2px;
  margin-bottom: var(--large);
  padding: var(--medium);
  .icon {
    padding: var(--large) 0;
    text-align: center;
  }
  .title {
    padding-top: var(--small);
    padding-bottom: var(--medium);
    color: var(--heading);
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    text-align: center;
  }
`

const FinalCTA = styled.div`
  p {
    margin: 0 auto;
    max-width: 75ch;
  }
`

const Massotherapie = () => (
  <Layout>
    <SEO title="Massothérapie" />
    <Hero title="Massothérapie" hero="massotherapie.jpg" />
    <div className="container highlow">
      <h2>
        La massothérapie est un ensemble de thérapies manuelles qui partagent un
        bon nombre de principes ayant les objectifs suivants:
      </h2>
      <BenefitsGrid>
        <Benefit style={{ gridArea: "item1" }}>
          <div className="icon">
            <img src={Benefit1} alt="Benefit 1" />
          </div>
          <div className="title">
            Amélioration de la circulation sanguine lymphatique et énergétique
          </div>
        </Benefit>
        <Benefit style={{ gridArea: "item2" }}>
          <div className="icon">
            <img src={Benefit2} alt="Benefit 2" />
          </div>
          <div className="title">Détente musculaire et nerveuse</div>
        </Benefit>
        <Benefit style={{ gridArea: "item3" }}>
          <div className="icon">
            <img src={Benefit3} alt="Benefit 3" />
          </div>
          <div className="title">
            Régulation des fonctions circulatoires respiratoires et digestives
          </div>
        </Benefit>
        <Benefit style={{ gridArea: "item4" }}>
          <div className="icon">
            <img src={Benefit4} alt="Benefit 4" />
          </div>
          <div className="title">Élimination des toxines</div>
        </Benefit>
        <Benefit style={{ gridArea: "item5" }}>
          <div className="icon">
            <img src={Benefit5} alt="Benefit 5" />
          </div>
          <div className="title">Diminution du stress</div>
        </Benefit>
        <Benefit style={{ gridArea: "item6" }}>
          <div className="icon">
            <img src={Benefit6} alt="Benefit 6" />
          </div>
          <div className="title">
            Restauration du bon fonctionnement des ressources naturelles de
            guérison du corps
          </div>
        </Benefit>
      </BenefitsGrid>
    </div>
    <div className="soft-cta-section">
      <div className="container side-padding">
        <svg
          width="52"
          height="49"
          viewBox="0 0 52 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48.2857 16.7924C47.9644 16.8589 43.6517 17.8213 38.1258 21.1589C37.2957 17.3019 34.8386 10.0802 27.5676 2.7354L25.7017 0.849976L23.8357 2.73786C16.539 10.1097 14.0922 17.3511 13.2698 21.2328C7.72335 17.8385 3.44402 16.8638 3.13046 16.7949L0 16.1254V19.1972C0 37.1407 10.0879 48.7339 25.7017 48.7339C41.0738 48.7339 51.4033 36.8651 51.4033 19.1972V16.1401L48.2857 16.7924ZM17.9886 24.2135C17.9937 24.1323 18.3793 16.5832 25.7017 8.13569C32.9341 16.5044 33.4096 24.12 33.4122 24.12V24.4276C30.5299 26.7215 27.9339 29.3265 25.676 32.1909C23.4116 29.3771 20.8339 26.8078 17.9886 24.5286V24.2135ZM25.7017 43.8111C12.0387 43.8111 6.29948 33.4831 5.30225 22.7317C9.55588 24.499 17.1661 28.7031 23.5684 37.8028L25.7299 40.8771L27.8503 37.7758C34.0855 28.6489 41.7935 24.4572 46.0882 22.7071C45.0653 33.1139 39.3338 43.8111 25.7017 43.8111Z"
            fill="#844e21"
          />
        </svg>
        <div className="text-block">
          <h3> Le bien-être et la détente du corps par le massage</h3>
          <p>
            La massothérapie est une thérapie par le toucher qui comporte
            plusieurs techniques différentes. C’est l’une des plus anciennes
            techniques naturelles qui consiste à traiter ou à soulager les
            dou-leurs par des manœuvres effectuées avec les mains (ou
            pieds/coudes/genoux), et avec différents lotions et huiles
            thérapeutiques. Les objectifs de ce traitement sont ; favoriser la
            relaxation d’un groupe musculaire ou de tout le corps et la détente
            musculaire et nerveuse, améliorer la circulation sanguine et
            lymphatique, l’assimilation et la digestion des aliments, assurer le
            bon fonctionnement des organes vitaux et amener l’éveil à une
            conscience psychosensorielle. Il agit sur la peau, les muscles, les
            tendons et les ligaments, ou vise des points d’acupuncture précis
            (sur les méridiens).
          </p>
          <h3>Contribuer au traitement de la fibromyalgie</h3>
          <p>
            Quelques recherches ont permis de constater des effets positifs
            significatifs sur les symptômes de la fibromyalgie, comme la
            réduction de la dépression, de la douleur et de l’utilisation
            d’analgésiques, l’amélioration de la mobilité, du sommeil et de la
            qualité de vie ainsi qu’une diminution du sentiment d’impuissance.
          </p>
          <h3>Les différents types de massage</h3>
          <p>
            Bien qu’on puisse facilement répertorier plus de 100 techniques
            différentes de massages et de tra-vail corporel. Selon la technique
            utilisée, les manœuvres peuvent être appliquées sur tout le corps ou
            sur une seule partie.
          </p>
        </div>
      </div>
    </div>
    <div className="container" style={{ padding: "var(--xl2) 0" }}>
      <div
        className="grid"
        style={{
          boxShadow: "var(--box-shadow)",
          marginBottom: "var(--xl)",
          gridColumnGap: 0,
        }}
      >
        <div className="col-md-6 vertical-center">
          <Image src="massotherapie-14.jpg" alt="Massotherapie 14" />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Pour Qui?</h2>
          <p>
            La massothérapie convient presque à toutes et à tous, des
            tout-petits aux personnes âgées.
          </p>
          <p>
            Les muscles se relâchent et les toxines sont évacuées. Alors inutile
            de traîner ces douleurs pen-dant plus longtemps et équipons-nous
            pour ne pas souffrir en vain.
          </p>
          <p>
            Pour les personnes souffrant de douleurs passagères ou chroniques
            avec une mobilité réduite ; hernie discale, douleurs de dos,
            lumbagos, douleurs cervicales, douleurs articulaires, douleurs
            musculaires, migraines, mobilité réduite post-chirurgie, tendinite,
            épaule gelée, sciatique, épicondy-lite, tensions musculaires,
            crampes, etc...
          </p>
          <p>
            La relaxation mentale et corporelle pour le bien-être de notre corps
            permet à tout âge de tendre à un meilleur équilibre de
            fonctionnement et à une prise de conscience psycholo-gique et
            corporelle.
          </p>
          <p>
            <strong>Contre-indications:</strong> - Toutes formes
            d’inflammations, toutes maladies de peau et tous problèmes
            circulatoires.
          </p>
        </div>
      </div>
      <div
        className="grid"
        style={{
          boxShadow: "var(--box-shadow)",
          gridColumnGap: 0,
        }}
      >
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Déroulement D’une Séance</h2>
          <p>
            La massothérapie recouvre une grande diversité de techniques, c’est
            pourquoi le déroulement des séances varie de façon considérable.
          </p>
          <ol>
            <li>Élaborer l’anamnèse du client et observation générale.</li>
            <li>Évaluation et analyse de la posture et de la mobilité.</li>
            <li>
              Utilisation de différentes techniques manuelles associées ayant
              pour objectif le relâchement musculaire et articulaire.
            </li>
          </ol>
          <p>
            Lors de la première séance, le traitement est défini ensemble pour
            atteindre le résultat souhaité : nombre de séances, fréquence,
            description de différents exercices et/ou étirements.
          </p>
        </div>
        <div className="col-md-6 vertical-center mobile-first">
          <Image src="massotherapie-15.jpg" alt="Massotherapie 15" />
        </div>
      </div>
    </div>
    <FinalCTA
      className="container side-padding"
      style={{ textAlign: "center" }}
    >
      <h2>Les Différentes Techniques Utilisées</h2>
      <p>
        La massothérapie suppose d’une bonne maîtrise de l'anatomie et de la
        physiologie des tissus mous, des os et des articulations.
      </p>
      <p>
        Le massage <strong>Classique</strong>, permet d'induire un relâchement
        général. Inspiré des mouvements du massage suédois traditionnel
        (effleurage, pétrissage, foulage, drainage, percussions),etc...
      </p>
      <div style={{ margin: "var(--xl) 0 var(--xl2)" }}>
        <Link to="/services/" className="btn soft-cta">
          En Savoir
        </Link>
      </div>
    </FinalCTA>
  </Layout>
)

export default Massotherapie
